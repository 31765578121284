import Development from "./Zones/Development"
import Test from "./Zones/Test"
import Staging from "./Zones/Staging"
import Production from "./Zones/Production"
import Unknown from "./Zones/Unknown"

class ZoneRegistry {
	public Development = new Development()
	public Test = new Test()
	public Staging = new Staging()
	public Production = new Production()
	private Unknown = new Unknown()

	public all = [
		this.Development,
		this.Test,
		this.Staging,
		this.Production,
		this.Unknown
	]

	public get = () =>
		this.all.filter((z) => z.accepts(window.location.host))[0]

	public getByCodeName = (codeName: string) =>
		this.all.find((x) => x.codename === codeName)

	public isDevelopment = () => this.get() === this.Development
	public isProduction = () => this.get() === this.Production
}

export const zoneRegistry = new ZoneRegistry()
