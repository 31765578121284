import { lazy } from "react"

export type LazyImport<TType> = () => Promise<{
	default: TType
}>

export function retry<T>(
	fn: (props?: never) => Promise<T>,
	retriesLeft = 5,
	interval = 1000
): Promise<T> {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error)
						return
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject)
				}, interval)
			})
	})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyRetry<T extends React.ComponentType<any>>(
	fn: () => Promise<{ default: T }>,
	retriesLeft = 5,
	interval = 1000
): React.LazyExoticComponent<T> {
	return lazy(() => retry(fn, retriesLeft, interval))
}
