import Zone from "../Zone"
import json from "../t4/Staging.json"

class Staging implements Zone {
	codename = "staging"
	accepts: (url: string) => boolean = (url) =>
		url.toLowerCase().indexOf(".stagem.co") !== -1
	airbrakeConfig = {
		projectId: 172741,
		projectKey: "9971aaf7e074bd76f9d61ac73c92d60d"
	}
	allowsUserIdOverride = false

	settings = json
}

export default Staging
