import Zone from "../Zone"
import json from "../t4/Production.json" // If zone cannot be ascertained then assume it's production

class Unknown implements Zone {
	codename = undefined as unknown as string
	accepts: (url: string) => boolean = () => true
	airbrakeConfig = {
		projectId: 339677,
		projectKey: "80a4bf118a9d949afc98802ee3bfc3ad"
	}
	allowsUserIdOverride = false

	settings = json
}

export default Unknown
